<template>
  <div class="">
    <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1" :title="link.title" :href="link.url" target="_blank">
      <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mdiInstagram, mdiFacebook, mdiTwitter } from '@mdi/js';

  export default {
    setup () {
      const socialLink = [
        {
          icon: mdiInstagram,
          title: 'Instagram',
          url: 'https://www.instagram.com/burger_breton/',
          color: '#e95950',
          colorInDark: '#e95950'
        },
        {
          icon: mdiFacebook,
          title: 'Facebook',
          url: 'https://facebook.com/pg/burgerbreton/',
          color: '#4267b2',
          colorInDark: '#4267b2'
        },
        {
          icon: mdiTwitter,
          title: 'Twitter',
          url: 'https://twitter.com/BurgerBreton',
          color: '#1da1f2',
          colorInDark: '#1da1f2'
        }
      ];

      return {
        socialLink
      };
    }
  };
</script>
