<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot />

    <!-- Navbar -->
    <template #navbar>
      <div class="navbar-content-container">
        <!-- Left Content -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          />
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <v-img
              :src="appLogo"
              max-height="45px"
              max-width="45px"
              alt="logo"
              contain
              class="me-3"
            />
            <h2 class="app-title text--primary">
              {{ appName }}
            </h2>
          </router-link>
        </div>

        <!-- Right Content -->
        <div class="d-flex align-center">
          <app-bar-notification />
          <span class="mr-2" />
          <app-bar-i18n />
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      />
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>&copy; {{ new Date().getFullYear() }} <a
          href="https://burgerbreton.com"
          class="text-decoration-none"
          target="_blank"
        >Burger Breton</a> - Tous droits réservés</span>
        <v-spacer />
        <span>
          <social-link />
        </span>
      </div>
    </template>

    <!-- App Content -->
    <template #v-app-content />
  </layout-content-horizontal-nav>
</template>

<script>
  import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue';
  import navMenuItems from '@/navigation/items';
  import AppBarNotification from '@/components/menu/AppBarNotification.vue';
  import AppBarI18n from '@/components/menu/AppBarI18n.vue';
  import SocialLink from '@/components/menu/SocialLink';
  import themeConfig from '@themeConfig';
  import { mdiHeartOutline } from '@mdi/js';

  export default {
    components: {
      SocialLink,
      LayoutContentHorizontalNav,
      AppBarNotification,
      AppBarI18n
    },
    setup () {
      return {
        navMenuItems,

        // App Config
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo,

        // Icons
        icons: {
          mdiHeartOutline
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
