import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'r'
  },
  {
    path: '*',
    redirect: 'r'
  },
  {
    path: '/r',
    name: 'receiving',
    component: () => import('@/views/Receiving.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('@/views/Confirmation.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/canceled',
    name: 'canceled',
    component: () => import('@/views/Canceled.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/tracker',
    name: 'tracker',
    component: () => import('@/views/Tracker.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/Invoice.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/Payment.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('@/views/Review.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      layout: 'content'
    }
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

export default router;
