import { mdiCommentTextOutline, mdiCreditCardOutline, mdiFood, mdiMapMarker, mdiPhoneOutline, mdiReceipt } from '@mdi/js';

export default [
  {
    title: 'tracker.title',
    icon: mdiMapMarker,
    to: 'tracker'
  },
  {
    title: 'order.title',
    icon: mdiFood,
    to: 'order'
  },
  {
    title: 'invoice.title',
    icon: mdiReceipt,
    to: 'invoice'
  },
  {
    title: 'payment.title',
    icon: mdiCreditCardOutline,
    to: 'payment'
  },
  {
    title: 'review.title',
    icon: mdiCommentTextOutline,
    to: 'review'
  },
  {
    title: 'contact.title',
    icon: mdiPhoneOutline,
    to: 'contact'
  }
];
