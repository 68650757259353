import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'vuetify/lib/locale/en';
import fr from 'vuetify/lib/locale/fr';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      ...require('@/plugins/i18n/locales/en.json'),
      $vuetify: en
    },
    fr: {
      ...require('@/plugins/i18n/locales/fr.json'),
      $vuetify: fr
    }
  }
});

const loadedLanguages = ['fr']; // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang;

  return lang;
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.json').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default);
    loadedLanguages.push(lang);

    return setI18nLanguage(lang);
  });
  /* eslint-enable */
}
