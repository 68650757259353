<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot />

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          MENU
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          MENU
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>&copy; {{ new Date().getFullYear() }} <a
          href="https://burgerbreton.com"
          class="text-decoration-none"
          target="_blank"
        >Burger Breton</a><span class="d-none d-md-inline"> - Tous droits réservés</span></span>
        <v-spacer />
        <span>
          <social-link />
        </span>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
  import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
  import navMenuItems from '@/navigation/items';
  import { mdiMenu, mdiHeartOutline } from '@mdi/js';
  import SocialLink from '@/components/menu/SocialLink';

  export default {
    components: {
      LayoutContentVerticalNav,
      SocialLink
    },
    setup () {
      return {
        navMenuItems,
        icons: {
          mdiMenu,
          mdiHeartOutline
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
