import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import Vue from 'vue';
import App from './App.vue';
import axios from '@/plugins/axios';
import vuetify from '@/plugins/vuetify';
import router from '@/router.js';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

if (process.env.VUE_APP_MODE === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://df00a88c27af41b1803478ef652ccd3b@o996279.ingest.sentry.io/4505381004443648',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'localhost:3001', 'localhost:8081', '192.168.86.164:8081', 'store.burgerbreton.com', 'api.store.burgerbreton.com']
      })
    ],
    attachProps: true,
    logErrors: true,
    environment: process.env.VUE_APP_MODE,
    maxBreadcrumbs: 50,
    // Setting a uniform sample rate for all transactions using the tracesSampleRate option in your SDK config to a number between 0 and 1.
    // (For example, to send 20% of transactions, set tracesSampleRate to 0.2.)
    tracesSampleRate: 0.05,
    trackComponents: true
  });
}

new Vue({
  router,
  store,
  i18n,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app');
