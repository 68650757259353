// axios
import axios from 'axios';
import Vue from 'vue';

const axiosIns = axios.create({
  // You can add your headers here
  baseURL: '/',
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0'
  },
  withCredentials: true,
  timeout: 30000
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
