import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app
  }
});
